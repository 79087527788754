<script>
import { registerMatomoEvent } from '@/helpers/matomo'
import { Action } from 'epmc-patterns/components/v2'

const range = {
  start: {
    startYear: 2017,
    startQuarter: 1,
  },
  end: {
    endYear: 2024,
    endQuarter: 3,
  },
}

const getRoadMapMetadataFromRange = (range) => {
  const { start, end } = range
  const { startYear, startQuarter } = start
  const { endYear, endQuarter } = end

  const data = []
  for (let y = endYear; y >= startYear; y--) {
    let q = 4
    let min = 1
    if (y === endYear) {
      q = endQuarter
    } else if (y === startYear) {
      min = startQuarter
    }
    const quarters = []
    for (let q2 = q; q2 >= min; q2--) {
      quarters.push(q2)
    }
    data.push({
      year: y,
      quarter: quarters,
      showRoadMap: false,
    })
  }
  return data
}

const quarterMonths = {
  1: {
    start: 'January',
    end: 'March',
  },
  2: {
    start: 'April',
    end: 'June',
  },
  3: {
    start: 'July',
    end: 'September',
  },
  4: {
    start: 'October',
    end: 'December',
  },
}

export default {
  metaInfo() {
    return {
      title: 'Roadmap - About - Europe PMC',
    }
  },
  components: {
    Action,
  },
  data() {
    return {
      roadmaps: getRoadMapMetadataFromRange(range),
      quarterMonths,
    }
  },
  methods: {
    getRoadmapFilename(year, quarter) {
      return `EuropePMCRoadmap${quarterMonths[quarter].start.slice(
        0,
        3
      )}-${quarterMonths[quarter].end.slice(0, 3)}${year}`
    },
    getRoadmapLink(year, quarter) {
      return `/doc/${this.getRoadmapFilename(year, quarter)}.pdf`
    },
    getRoadmapImgSrc(year, quarter) {
      return require('@/assets/roadmaps/' +
        this.getRoadmapFilename(year, quarter) +
        '.jpg')
    },
    toggleYear(index) {
      this.roadmaps[index].showRoadMap = !this.roadmaps[index].showRoadMap
      if (this.roadmaps[index].showRoadMap === true) {
        this.registerMatomoEventForRoadmap(
          this.roadmaps[index].year + 'Expanded'
        )
      }
    },
    registerMatomoEventForRoadmap(eventName) {
      registerMatomoEvent('Roadmap', 'Year Information', eventName)
    },
  },
}
</script>
<template>
  <div id="roadmap">
    <div class="grid-row">
      <div class="col-10">
        <h1>Europe PMC Roadmap</h1>
        <p>
          The Europe PMC roadmap shows the features we are currently working on
          and future development priorities. The roadmap will be updated and
          published quarterly.
        </p>
        <template v-for="(roadmap, index) in roadmaps">
          <template v-if="index === 0">
            <p :key="index + 'link'">
              <a :href="getRoadmapLink(roadmap.year, roadmap.quarter[0])"
                >Europe PMC Roadmap - Quarter {{ roadmap.quarter[0] }},
                {{ roadmap.year }}
                {{ quarterMonths[roadmap.quarter[0]].start }} -
                {{ quarterMonths[roadmap.quarter[0]].end }} (PDF)</a
              >
            </p>
            <div :key="index + 'img'">
              <a :href="getRoadmapLink(roadmap.year, roadmap.quarter[0])">
                <img
                  :src="getRoadmapImgSrc(roadmap.year, roadmap.quarter[0])"
                  :alt="getRoadmapFilename(roadmap.year, roadmap.quarter[0])"
                />
              </a>
            </div>
            <p :key="index + 'p1'">
              We are committed to improving the user experience of Europe PMC,
              by understanding the needs of the people who use literature
              services, including life sciences researchers, curators,
              developers and text miners.
            </p>
            <p :key="index + 'p2'">Previous roadmaps:</p>
          </template>
          <template>
            <h2 :key="index + 'year'" class="metadata--dropdown-title">
              <action
                v-if="roadmap.quarter.length > 1"
                id="roadmap--expand--yearlyInformation"
                icon-pos="right"
                @click="toggleYear(index)"
              >
                {{ roadmap.year }}
                <i
                  slot="icon"
                  :class="[
                    'fas',
                    roadmaps[index].showRoadMap
                      ? 'fa-caret-down'
                      : 'fa-caret-right',
                  ]"
                />
              </action>
            </h2>
            <div
              v-show="roadmaps[index].showRoadMap"
              :key="index + 'yearlylink'"
            >
              <template v-for="(q, qindex) in roadmap.quarter">
                <p v-if="qindex !== 0 || index !== 0" :key="qindex + 'yearlyp'">
                  <a :href="getRoadmapLink(roadmap.year, q)"
                    >Quarter {{ q }}, {{ roadmap.year }}
                    {{ quarterMonths[q].start }} -
                    {{ quarterMonths[q].end }} (PDF)</a
                  >
                </p>
              </template>
            </div>
          </template>
        </template>
        <p>
          If you would like to provide feedback on any aspect of Europe PMC,
          request a new feature, or participate in a user research session,
          please use our
          <a href="/feedback?url=http://europepmc.org/Roadmap">feedback form</a>
          to get in touch.
        </p>
        <h2>Europe PMC mission and objectives</h2>
        <p>
          "To build open, full-text scientific literature resources and support
          innovation and discovery by engaging users, enabling contributors, and
          integrating related research outputs."
        </p>
        <p>Europe PMC has 6 key objectives:</p>
        <ol>
          <li>
            Provide a world-class reliable and high-performance open access full
            text database and related services.
          </li>
          <li>
            Support and lead in the development of emerging open publication
            workflows.
          </li>
          <li>
            Integrate the open access literature with major research outputs and
            objects such as data, software, authors, grants and institutions.
          </li>
          <li>
            Build an open community platform for machine learning and text
            mining.
          </li>
          <li>
            Support open science through reuse of Europe PMC content, links and
            semantic enrichments.
          </li>
          <li>
            Ensure that Europe PMC meets the needs of all key stakeholder user
            groups.
          </li>
        </ol>
        <h2>
          Provide a world-class reliable and high-performance open access full
          text database and related services
        </h2>
        <p>
          We aim to continuously improve the quality and performance of the
          service, including upgrades to the technical architecture and
          processes, continuous monitoring of Europe PMC services, for quality
          assurance, and improvements to the user experience.
        </p>
        <h2>
          Support and lead in the development of emerging open publication
          workflows
        </h2>
        <p>
          Europe PMC is a strong supporter of open and transparent publishing
          workflows, one of the central tenets of Open Science. Our ambition is
          to archive leading new types of content, such as preprints, in Europe
          PMC, to make them more discoverable, and available for further
          scrutiny, reuse and integration.
        </p>
        <h2>
          Integrate the open access literature with major research outputs and
          objects such as data, software, authors, grants and institutions
        </h2>
        <p>
          Linking research outputs via persistent identifiers supports science
          by making related information findable and provides provenance
          scientific conclusions, building trust. In addition, it supports
          research reporting for funders and employers who need to understand
          the impact of research.
        </p>
        <h2>
          Build an open community platform for machine learning and text mining
        </h2>
        <p>
          We encourage text mining groups and developers to use Europe PMC
          content and APIs develop new technologies, which can improve
          information retrieval and researcher workflows. For example, text
          miners can access and mine full text content for biological concepts
          and terms. To date, about 10 text mining groups have uploaded over 20
          different types of biological entity, with over 1.3 billion
          annotations altogether. All annotations are made available through the
          annotations API, which allows their reuse in other contexts and
          displayed to users of Europe PMC on abstract and article pages.
        </p>

        <h2>
          Support open science through reuse of Europe PMC content, links and
          semantic enrichments
        </h2>
        <p>
          Europe PMC contains a wealth of Open Access full text content and
          linked resources. We want to build on the content, links and semantic
          enrichments available in Europe PMC to devise better ways to search
          and retrieve publications and related content.
        </p>

        <h2>
          Ensure that Europe PMC meets the needs of all key stakeholder user
          groups
        </h2>
        <p>
          Europe PMC's user communities include researchers, funders, the
          scientifically-engaged public, developers and text miners. Usage by
          these communities encompasses a wide range of different tasks which
          may be scientific, administrative or consumptive/innovative. Europe
          PMC aims to improve the user experience for these user communities and
          encourage and increase their usage of Europe PMC services.
        </p>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
#roadmap {
  margin-top: $base-unit * 6.5;
  margin-bottom: $base-unit * 13;
}

h2.metadata--dropdown-title {
  margin: ($base-unit * 3) 0 $base-unit;
  .action {
    font-size: $base-unit * 4;
  }
}
</style>
